import { HeatmapMetric, HeatmapOptions as HeatmapOptionsDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { FromDto, ToDto } from '../interfaces'
import { HeatmapDashboardType } from '../../service/dashboard/types'
import { FILTER_TOP_BY_OPTIONS } from '../../pages/dashboards/heatmap/FilterTopOption'

export type FilterByTop = HeatmapOptionsDto['filterByTop']
export type GridSize = HeatmapOptionsDto['gridSize']
export type HeatmapMetricType = HeatmapOptionsDto['metric']['type']

export class HeatmapOptions implements FromDto<HeatmapOptionsDto>, ToDto<HeatmapOptionsDto> {
    readonly type: HeatmapDashboardType

    constructor(private dto: HeatmapOptionsDto, type: HeatmapDashboardType) {
        this.type = type
        makeAutoObservable(this)
    }

    get appId(): string | undefined {
        return this.dto.appId
    }

    get floorPlanId(): string | undefined {
        return this.dto.floorPlanId
    }

    get streamId(): string | undefined {
        return this.dto.streamId
    }

    get deploymentIds(): string[] {
        return this.dto.deploymentIds || []
    }

    get itemTypes(): string[] {
        return this.dto.itemTypes || []
    }

    set itemTypes(value: string[]) {
        this.dto.itemTypes = value
    }

    get metric(): HeatmapMetric {
        return this.dto.metric
    }

    set metric(value: HeatmapMetric) {
        this.dto.metric = value
    }

    get metricType(): HeatmapMetricType {
        return this.metric.type
    }

    get gridSize(): GridSize | undefined {
        return this.dto.gridSize
    }

    set gridSize(value: GridSize | undefined) {
        this.dto.gridSize = value
    }

    get filterByTop(): FilterByTop {
        return this.dto.filterByTop || 'duration'
    }

    set filterByTop(value: FilterByTop) {
        this.dto.filterByTop = value
    }

    get filterToTopIds(): number | undefined {
        return this.dto.filterToTopIds || FILTER_TOP_BY_OPTIONS[0]
    }

    set filterToTopIds(value: number | undefined) {
        this.dto.filterToTopIds = value
    }

    fromDto(dto: HeatmapOptionsDto): void {
        this.dto = dto
    }

    toDto(): HeatmapOptionsDto {
        return toJS(this.dto)
    }
}
