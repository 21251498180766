import { ReactElement } from 'react'
import { Box, BoxProps, Stack } from '@mui/material'
import { asSxArray } from '@kibsi/ui-components'

export type OptionsGroupProps = {
    shadows?: boolean
} & BoxProps

export function OptionsGroup({ shadows, children, sx, ...props }: OptionsGroupProps): ReactElement {
    const style = {
        borderRadius: 0.5,
        boxShadow: shadows ? 1 : 0,
        p: 1,
        backgroundColor: 'white',
    }

    return (
        <Box sx={[style, ...asSxArray(sx)]} {...props}>
            <Stack direction="row" gap={1} sx={{ height: 1 }}>
                {children}
            </Stack>
        </Box>
    )
}
