import { IDisposer } from 'mobx-utils'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { Range } from 'model/date'
import { Dimension } from 'util/dimension'
import { HeatmapOptions as HeatmapOptionsDto } from '@kibsi/ks-tenant-types'
import { HistoryService } from 'service/history'
import { FloorPlanHeatmapParams, HeatmapMetric, ItemPathParams } from '@kibsi/ks-history-types'
import logger from 'logging/logger'
import { GridSize, HeatmapOptions } from './heatmap.options'
import { HeatmapValue } from './heatmap.value'
import { ItemPathValue } from './item-path.value'

export const HEATMAP_FILTER_TO_TOP_IDS = 10

export class FloorPlanData {
    private reactions: IDisposer[] = []
    startEnd?: Range
    heatmapValues: HeatmapValue[] = []
    itemPathValues: ItemPathValue[] = []
    loading = false

    constructor(
        private id: string,
        private service: HistoryService,
        public heatmapOptions?: HeatmapOptions,
        public dimension?: Dimension,
    ) {
        this.startEnd = { start: 0, end: 0 }
        makeAutoObservable(this)
        this.bindReactions()
    }

    get dashboardId(): string {
        return this.id
    }

    private reactionUpdate() {
        return {
            ...this.heatmapOptions?.toDto(),
            range: this.range,
        }
    }

    set range(value: Range | undefined) {
        this.startEnd = value
    }

    get range(): Range | undefined {
        return this.startEnd
    }

    getValues(): HeatmapValue[] | ItemPathValue[] {
        switch (this.heatmapOptions?.type) {
            case 'heatmap':
                return this.heatmapValues
            case 'path':
                return this.itemPathValues
            default:
                return []
        }
    }

    refresh(): void {
        this.updateState(this.heatmapOptions, this.range)
    }

    private updateState(options?: HeatmapOptions, r?: Range): void {
        if (!options) {
            return
        }

        const { floorPlanId, streamId, metric, deploymentIds, itemTypes, gridSize, filterByTop, filterToTopIds } =
            options
        if (
            (!floorPlanId && !streamId) ||
            !metric ||
            !r?.start ||
            !r.end ||
            itemTypes?.length === 0 ||
            deploymentIds?.length === 0 ||
            !filterToTopIds
        ) {
            this.heatmapValues = []
            this.itemPathValues = []
            return
        }

        this.loading = true

        this.heatmapValues = this.heatmapValues.filter((v) => itemTypes.includes(v.itemTypeId))
        this.itemPathValues = this.itemPathValues.filter((v) => itemTypes.includes(v.itemTypeId))

        switch (this.heatmapOptions?.type) {
            case 'heatmap': {
                itemTypes.forEach((itemTypeId, index) => {
                    const hmv = this.getHeatmapValue(itemTypeId)
                    hmv.loading = true

                    const id = floorPlanId || streamId || ''
                    const params = this.buildParams(deploymentIds, metric, itemTypeId, gridSize, filterToTopIds)
                    this.updateHeatmaps(id, index, hmv, params, itemTypes, r, floorPlanId)
                })
                break
            }
            case 'path': {
                if (filterByTop) {
                    itemTypes.forEach((itemTypeId, index) => {
                        const ipv = this.getItemPathValue(itemTypeId)
                        ipv.loading = true

                        const id = floorPlanId || streamId || ''
                        const params = this.buildParams(deploymentIds, metric, itemTypeId, gridSize, filterToTopIds)
                        this.updateItemPaths(
                            id,
                            index,
                            ipv,
                            {
                                ...params,
                                filterByTop,
                            },
                            itemTypes,
                            r,
                            floorPlanId,
                        )
                    })
                }
                break
            }
            default:
                break
        }
    }

    private buildParams(
        deploymentIds: string[],
        metric: HeatmapMetric,
        itemTypeId: string,
        gridSize: GridSize,
        filterToTopIds: number,
    ): FloorPlanHeatmapParams {
        const type = this.heatmapOptions?.type
        const groupByUniqueId = type === 'path'
        let params: FloorPlanHeatmapParams = {
            metric,
            deploymentIds,
            groupByUniqueId,
            itemTypes: [itemTypeId],
            grid: gridSize || 'small',
        }

        if (groupByUniqueId) {
            params = {
                ...params,
                filterToTopIds,
            }
        }

        return params
    }

    private updateHeatmaps(
        id: string,
        index: number,
        hmv: HeatmapValue,
        params: FloorPlanHeatmapParams,
        itemTypes: string[],
        r: Range,
        floorPlanId?: string,
    ) {
        this.service
            .floorPlanHeatmap(
                id,
                new Date(r.start).toISOString(),
                new Date(r.end).toISOString(),
                params,
                floorPlanId ? 'floorPlan' : 'stream',
            )
            .then(({ heatmap, gridPixels }) => {
                runInAction(() => {
                    if (hmv) {
                        hmv.loading = false
                        hmv.gridValues = heatmap
                        hmv.gridPixels = gridPixels
                    }

                    if (index === itemTypes.length - 1) {
                        this.loading = false
                    }
                })
            })
            .catch((e) => {
                logger.error(e)
                runInAction(() => {
                    this.loading = false
                })
            })
    }

    private updateItemPaths(
        id: string,
        index: number,
        ipv: ItemPathValue,
        params: ItemPathParams,
        itemTypes: string[],
        r: Range,
        floorPlanId?: string,
    ) {
        this.service
            .floorPlanItemPath(
                id,
                new Date(r.start).toISOString(),
                new Date(r.end).toISOString(),
                params,
                floorPlanId ? 'floorPlan' : 'stream',
            )
            .then((itemPathResult) => {
                runInAction(() => {
                    if (ipv) {
                        ipv.loading = false
                        ipv.pathValues = itemPathResult
                    }

                    if (index === itemTypes.length - 1) {
                        this.loading = false
                    }
                })
            })
            .catch((e) => {
                logger.error(e)
                runInAction(() => {
                    this.loading = false
                })
            })
    }

    private getHeatmapValue(itemTypeId: string): HeatmapValue {
        let value = this.heatmapValues.find((v) => v.itemTypeId === itemTypeId)
        if (!value) {
            value = new HeatmapValue(itemTypeId, this.dimension)
            this.heatmapValues.push(value)
        }
        return value
    }

    private getItemPathValue(itemTypeId: string): ItemPathValue {
        let value = this.itemPathValues.find((v) => v.itemTypeId === itemTypeId)
        if (!value) {
            value = new ItemPathValue(itemTypeId)
            this.itemPathValues.push(value)
        }
        return value
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.reactionUpdate(),
                ({ range, ...options }) => {
                    if (!options || !this.heatmapOptions) {
                        return
                    }
                    this.updateState(new HeatmapOptions(options as HeatmapOptionsDto, this.heatmapOptions.type), range)
                },
            ),
        )
    }
}
