import { inject, injectable } from 'inversify'
import { HistoryKibsiClient } from '@kibsi/ks-client-sdk'
import type {
    ARTItemState,
    CounterAggregateResult,
    DeploymentARTSnapshot,
    HeuristicQueryResult,
    HistorySnapshotInterval,
    ItemAggregateResult,
    ItemCountResult,
    DistinctItemsRequest,
    FloorPlanHeatmapParams,
    FloorPlanHeatmapResult,
    GridEventParams,
    GridEventResult,
    ItemPathResult,
    ItemPathParams,
} from '@kibsi/ks-history-types'
import TYPES from 'config/inversify.types'
import { RequestStore } from 'store/request.store'
import type { HistoryInterval, HistoryService } from './history.service'
import { parseSnapshot, parseSnapshotInterval, parseSnapshotState } from './helper'

export type HistoryApi = Pick<
    InstanceType<typeof HistoryKibsiClient>,
    | 'itemAggregates'
    | 'itemCounts'
    | 'heuristicCounts'
    | 'snapshot'
    | 'snapshotInterval'
    | 'itemDetails'
    | 'distinctItems'
    | 'counterAggregates'
    | 'floorPlanHeatmap'
    | 'heatmapGridEvents'
    | 'itemPath'
>

@injectable()
export class HistoryServiceImpl implements HistoryService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.HistoryApi) private api: HistoryApi,
    ) {}

    itemAggregates(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
    ): Promise<ItemAggregateResult> {
        return this.req.once(`item.aggregates.${deploymentId}.${startTime}.${endTime}.${interval}`, () =>
            this.api.itemAggregates(deploymentId, startTime, endTime, interval),
        )
    }

    distinctItems(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
        queryRequest?: DistinctItemsRequest,
    ): Promise<ItemCountResult> {
        return this.api.distinctItems(deploymentId, startTime, endTime, interval, queryRequest)
    }

    counterAggregates(
        deploymentId: string,
        itemType: string,
        name: string,
        counter: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
    ): Promise<CounterAggregateResult> {
        return this.req.once(
            `count.aggregates.${deploymentId}.${itemType}.${name}.${counter}.${startTime}.${endTime}`,
            () => this.api.counterAggregates(deploymentId, itemType, name, counter, startTime, endTime, interval),
        )
    }

    itemCounts(deploymentId: string, startTime: string, endTime: string): Promise<ItemCountResult> {
        return this.req.once(`item.counts.${deploymentId}.${startTime}.${endTime}`, () =>
            this.api.itemCounts(deploymentId, startTime, endTime),
        )
    }

    heuristicCounts(deploymentId: string, startTime: string, endTime: string): Promise<HeuristicQueryResult> {
        return this.req.once(`heuristic.counts.${deploymentId}.${startTime}.${endTime}`, () =>
            this.api.heuristicCounts(deploymentId, startTime, endTime),
        )
    }

    async snapshot(deploymentId: string, time: string): Promise<DeploymentARTSnapshot> {
        const resp = await this.req.once(`snapshot.${deploymentId}.${time}`, () =>
            this.api.snapshot(deploymentId, time),
        )

        resp.snapshot = parseSnapshot(resp.snapshot)

        return resp
    }

    async snapshotInterval(deploymentId: string, startTime: string, endTime: string): Promise<HistorySnapshotInterval> {
        const resp = await this.req.once(`snapshot.interval.${deploymentId}.${startTime}.${endTime}`, () =>
            this.api.snapshotInterval(deploymentId, startTime, endTime),
        )

        resp.snapshot = parseSnapshotInterval(resp.snapshot)

        return resp
    }

    async itemDetails(deploymentId: string, startTime: string, endTime: string, id: string): Promise<ARTItemState[]> {
        const resp = await this.req.once(`item.details.${deploymentId}.${startTime}.${endTime}`, () =>
            this.api.itemDetails(deploymentId, startTime, endTime, id),
        )

        return resp.map(parseSnapshotState)
    }

    floorPlanHeatmap(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest: FloorPlanHeatmapParams,
        imageType?: 'floorPlan' | 'stream',
    ): Promise<FloorPlanHeatmapResult> {
        return this.req.once(
            `floorPlanHeatmap.${floorPlanId}.${startTime}.${endTime}.${imageType}.${JSON.stringify(queryRequest)}`,
            () => this.api.floorPlanHeatmap(floorPlanId, startTime, endTime, queryRequest, undefined, imageType),
        )
    }

    heatmapGridEvents(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest: GridEventParams,
        imageType?: 'floorPlan' | 'stream',
    ): Promise<GridEventResult> {
        return this.req.once(
            `heatmapGridEvents.${floorPlanId}.${startTime}.${endTime}.${imageType}.${JSON.stringify(queryRequest)}`,
            () => this.api.heatmapGridEvents(floorPlanId, startTime, endTime, queryRequest, undefined, imageType),
        )
    }

    floorPlanItemPath(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest: ItemPathParams,
        imageType?: 'floorPlan' | 'stream',
    ): Promise<ItemPathResult> {
        return this.req.once(
            `floorPlanItemPath.${floorPlanId}.${startTime}.${endTime}.${imageType}.${JSON.stringify(queryRequest)}`,
            () => this.api.itemPath(floorPlanId, startTime, endTime, queryRequest, undefined, imageType),
        )
    }
}
