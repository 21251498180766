import { ItemPathChartData } from 'model/item-path'
import { makeAutoObservable } from 'mobx'
import { ItemPathResult } from '@kibsi/ks-history-types'
import {
    SeriesMappointOptions,
    SeriesMappointDataOptions,
    SeriesFlowmapOptions,
    SeriesFlowmapDataOptions,
} from 'highcharts'

export class ItemPathValue {
    pathValues: ItemPathResult = {}
    loading = false

    constructor(public itemTypeId: string) {
        makeAutoObservable(this)
    }

    get chartData(): ItemPathChartData {
        const mapPointSeries: SeriesMappointOptions[] = []
        const flowMapSeries: SeriesFlowmapOptions[] = []

        Object.keys(this.pathValues).forEach((pathId, index) => {
            const pathName = pathId.slice(-4)
            const v = this.pathValues[pathId]
            const mapPointData: SeriesMappointDataOptions[] = []
            const flowMapData: SeriesFlowmapDataOptions[] = []

            v.forEach(({ time, x, y }) => {
                mapPointData.push({
                    id: `${time}|${pathName}`,
                    x,
                    y,
                })
            })

            if (v.length > 1) {
                for (let i = 1; i < v.length; i++) {
                    flowMapData.push({
                        from: `${v[i - 1].time}|${pathName}`,
                        to: `${v[i].time}|${pathName}`,
                        weight: 1,
                        curveFactor: 0,
                    })
                }
            }

            // mappoint
            mapPointSeries.push({
                zIndex: 2,
                id: `mappoint.${pathName}`,
                name: pathName,
                type: 'mappoint',
                data: mapPointData,
                marker: {
                    enabled: false,
                },
                showInLegend: false,
                accessibility: {
                    enabled: false,
                },
            })

            // flowmap arrow
            flowMapSeries.push({
                id: `flowmap.${pathName}`,
                name: pathName,
                type: 'flowmap',
                accessibility: {
                    enabled: false,
                },
                markerEnd: {
                    height: '60%',
                },
                showInLegend: true,
                data: flowMapData,
                index,
                states: {
                    hover: {
                        borderColor: 'white',
                    },
                    inactive: {
                        opacity: 0,
                    },
                },
            })
        })

        const data = [...mapPointSeries, ...flowMapSeries]

        return {
            itemTypeId: this.itemTypeId,
            data,
        }
    }
}
