import { ReactElement } from 'react'
import { KBPanelBasicItem } from '@kibsi/ui-components'
import { GridView } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { FilterByTop } from 'store/dashboard/heatmap.options'
import { NumberOption } from './NumberOption'
import { FilterTopByOption } from './FilterTopByOption'

export const FILTER_TOP_BY_OPTIONS = [5, 10, 20]

export type FilterTopOptionProps = {
    topBy?: number
    onTopBy?: (topBy: number) => void
    filterBy?: FilterByTop
    onFilterBy?: (filterBy: FilterByTop) => void
}

export function FilterTopOption({
    topBy = FILTER_TOP_BY_OPTIONS[0],
    onTopBy,
    filterBy,
    onFilterBy,
}: FilterTopOptionProps): ReactElement {
    const { t } = useTranslation()

    return (
        <>
            <KBPanelBasicItem
                icon={<GridView />}
                title={t('dashboard.display.showTopBy')}
                secondary={<NumberOption options={FILTER_TOP_BY_OPTIONS} value={topBy} onChange={onTopBy} />}
            />
            {onFilterBy && (
                <KBPanelBasicItem secondary={<FilterTopByOption value={filterBy} onChange={onFilterBy} />} />
            )}
        </>
    )
}
