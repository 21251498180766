import { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Stack,
    SvgIconProps,
    SxProps,
    Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'
import { asSxArray } from '../../utils/mui'
import { KBTab, KBTabs } from '../tabs'

type ResponsiveStyleValue<T> = T | Array<T | null> | { [key: string]: T | null }

export type KBOption = {
    id: string
    title?: string
    icon?: FunctionComponent<SvgIconProps>
}

export type KBCollapsibleCardProps = {
    icon?: ReactElement
    title?: string
    startItem?: ReactElement | ReactElement[]
    options?: KBOption[]
    endElement?: ReactElement
    selectedOption?: string
    onOptionChange?: (option?: string) => void
    expand?: boolean
    collapsible?: boolean
    children?: ReactNode
    onExpand?: (expand: boolean) => void
    collapsedSize?: number
    loading?: boolean
    dense?: boolean
    backgroundColor?: string
    width?: ResponsiveStyleValue<any>
    detailsSx?: SxProps
    'data-testid'?: string
}

export function KBCollapsibleCard({
    title,
    icon,
    startItem,
    options,
    endElement,
    children,
    selectedOption,
    expand = true,
    onOptionChange,
    onExpand,
    collapsible = true,
    collapsedSize = 0,
    loading,
    dense,
    backgroundColor,
    width,
    detailsSx,
    'data-testid': dataTestId,
}: KBCollapsibleCardProps): ReactElement {
    const [expanded, setExpanded] = useState<boolean>(collapsible ? true : expand)

    const borderRadius = '4px'

    const rootStyle = {
        width,
        backgroundColor: '#f4f4f4',
        '& .MuiAccordionSummary-root': {
            backgroundColor: 'white',
            borderRadius,
            pr: 1,
        },
        '& .MuiAccordionSummary-content': {
            cursor: 'default',
        },
        '&::before': {
            height: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderRadius: `${borderRadius} ${borderRadius} 0 0`,
        },
    }

    const summaryStyle = {
        ...(dense && { minHeight: '34px' }),
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            minWidth: 0,
        },
        pointerEvents: 'none',
        overflowX: 'clip',
    }

    const detailsStyle = [{ padding: '9px', backgroundColor }, ...asSxArray(detailsSx)]

    const selectedOptionIndex = () => {
        const index = options?.findIndex((opt) => opt.id === selectedOption) || 0
        return index < 0 ? 0 : index
    }

    const onExpanded = () => {
        const value = !expanded
        setExpanded(value)
        onExpand?.(value)
    }

    const expandIcon = (
        <Stack sx={{ alignItems: 'center', height: 1, width: '30px', pointerEvents: 'auto', cursor: 'pointer' }}>
            {loading ? (
                <CircularProgress size="16px" />
            ) : (
                <ExpandMore sx={{ cursor: 'pointer' }} onClick={onExpanded} />
            )}
        </Stack>
    )

    useEffect(() => {
        if (!collapsible) {
            setExpanded(true)
        }
    }, [collapsible])

    return (
        <Accordion
            sx={rootStyle}
            disableGutters
            expanded={expanded}
            TransitionProps={{ collapsedSize } as unknown as TransitionProps}
            data-testid={dataTestId}
        >
            {/* header */}
            <AccordionSummary sx={summaryStyle} expandIcon={(collapsible || loading) && expandIcon}>
                <Stack
                    flex={1}
                    gap={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    minWidth={0}
                    sx={{
                        pointerEvents: 'auto',
                    }}
                >
                    <Stack direction="row" alignItems="center" gap={2} flexShrink={0}>
                        {icon && <Box sx={{ width: '24px', height: '24px', color: 'action.active' }}>{icon}</Box>}
                        <Typography
                            noWrap
                            variant={dense ? 'subtitle2' : 'subtitle1'}
                            sx={{ ...(dense && { fontSize: '14px' }) }}
                        >
                            {title}
                        </Typography>
                        {startItem}
                    </Stack>

                    {/* options */}
                    <Stack direction="row" flexShrink={1} minWidth={0} alignItems="center">
                        {(options || []).length > 0 && (
                            <KBTabs
                                value={selectedOptionIndex()}
                                onChange={(e, value: number) => {
                                    e.stopPropagation()
                                    onOptionChange?.(options?.[value].id)
                                }}
                                data-testid="tabs"
                            >
                                {options?.map(({ id, title: ot, icon: Icon }) => (
                                    <KBTab key={id} label={ot} icon={Icon} data-testid={`${dataTestId}-tab-${id}`} />
                                ))}
                            </KBTabs>
                        )}
                    </Stack>
                </Stack>

                {/* end element */}
                {endElement}
            </AccordionSummary>

            {/* body */}
            <AccordionDetails sx={detailsStyle}>{children}</AccordionDetails>
        </Accordion>
    )
}
