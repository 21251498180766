import { ReactElement } from 'react'
import { OptionsGroup } from 'components/options/OptionsGroup'
import { KBOptionButton } from '@kibsi/ui-components'

export type NumberOptionProps = {
    value?: number
    onChange?: (value: number) => void
    options?: number[]
}

export function NumberOption({ value, onChange, options = [] }: NumberOptionProps): ReactElement {
    return (
        <OptionsGroup sx={{ p: 0 }}>
            {options?.map((v) => (
                <KBOptionButton
                    key={v}
                    active={value === v}
                    size="small"
                    onClick={() => {
                        onChange?.(v)
                    }}
                >
                    {v}
                </KBOptionButton>
            ))}
        </OptionsGroup>
    )
}
