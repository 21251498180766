import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionsGroup } from 'components/options/OptionsGroup'
import { KBOptionButton } from '@kibsi/ui-components'
import { FilterByTop } from 'store/dashboard/heatmap.options'

const TOP_BY_OPTIONS: FilterByTop[] = ['distance', 'duration', 'area']

export type FilterTopByOptionProps = {
    value?: FilterByTop
    onChange?: (value: FilterByTop) => void
}

export function FilterTopByOption({ value = 'duration', onChange }: FilterTopByOptionProps): ReactElement {
    const { t } = useTranslation()

    return (
        <OptionsGroup sx={{ p: 0 }}>
            {TOP_BY_OPTIONS.map((o) => (
                <KBOptionButton
                    key={o}
                    active={value === o}
                    size="small"
                    onClick={() => {
                        onChange?.(o)
                    }}
                >
                    {t(`dashboard.display.${o}`)}
                </KBOptionButton>
            ))}
        </OptionsGroup>
    )
}
